export const TIME_FORMAT_24 = 'HH:mm:ss'
export const TIME_FORMAT_24_MINS = 'HH:mm'
export const TIME_FORMAT_24_HRS = 'HH:00:00'
export const TIME_FORMAT_24_HRS_MINS = 'HH:mm:00'
export const TIMEZONE_FORMAT = 'z'
export const DATE_FORMAT_EN = 'MM/dd/yyyy'
export const DATE_FORMAT_RU = 'dd.MM.yyyy'
export const DATE_PICKER_FORMAT_EN = `${DATE_FORMAT_EN} ${TIME_FORMAT_24}`
export const DATE_PICKER_FORMAT_RU = `${DATE_FORMAT_RU} ${TIME_FORMAT_24}`
export const DATE_PICKER_FORMAT_TIMEZONE_EN = `${DATE_FORMAT_EN} ${TIME_FORMAT_24}, ${TIMEZONE_FORMAT}`
export const DATE_PICKER_FORMAT_TIMEZONE_RU = `${DATE_FORMAT_RU} ${TIME_FORMAT_24}, ${TIMEZONE_FORMAT}`
export const DATE_PICKER_FORMAT_HRS_EN = `${DATE_FORMAT_EN} HH:00:00`
export const DATE_PICKER_FORMAT_HRS_RU = `${DATE_FORMAT_RU} HH:00:00`
export const DATE_PICKER_FORMAT_HRS_MINS_EN = `${DATE_FORMAT_EN} ${TIME_FORMAT_24_HRS_MINS}`
export const DATE_PICKER_FORMAT_HRS_MINS_RU = `${DATE_FORMAT_RU} ${TIME_FORMAT_24_HRS_MINS}`
export const DATE_INPUT_FORMAT = 'yyyy-MM-dd'

export const LOCALE_TO_DATE_TIME_TIMEZONE_FORMAT: Record<string, string> = {
  en: DATE_PICKER_FORMAT_TIMEZONE_EN,
  ru: DATE_PICKER_FORMAT_TIMEZONE_RU,
}

export const LOCALE_TO_DATE_TIME_FORMAT: Record<string, string> = {
  en: DATE_PICKER_FORMAT_EN,
  ru: DATE_PICKER_FORMAT_RU,
}

export const LOCALE_TO_DATE_TIME_FORMAT_HRS: Record<string, string> = {
  en: DATE_PICKER_FORMAT_HRS_EN,
  ru: DATE_PICKER_FORMAT_HRS_RU,
}

export const LOCALE_TO_DATE_TIME_FORMAT_HRS_MINS: Record<string, string> = {
  en: DATE_PICKER_FORMAT_HRS_MINS_EN,
  ru: DATE_PICKER_FORMAT_HRS_MINS_RU,
}

export const LOCALE_TO_DATE_FORMAT: Record<string, string> = {
  en: DATE_FORMAT_EN,
  ru: DATE_FORMAT_RU,
}

export const STANDARD_BACKEND_DATE_FORMAT = `yyyy-MM-dd'T'${TIME_FORMAT_24}`

export const INPUT_DATE_FORMAT_FULL = `${DATE_INPUT_FORMAT} ${TIME_FORMAT_24}`
export const INPUT_DATE_FORMAT_HRS = `${DATE_INPUT_FORMAT} HH:00:00`

export enum DateFormat {
  datetime = 'datetime',
  date = 'date',
}

export const INVALID_DATE = 'Invalid Date'

export const GMT_TIMEZONE = 'GMT'
export const UTC_TIMEZONE = 'UTC'

export const DATE_NULL_DUMP = '0001-01-01T00:00:00Z'

export enum FormatOption {
  Full = 0,
  Hrs = 1,
  HrsMins = 2,
  Date = 3,
  Timezone = 4,
}
