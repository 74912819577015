import { useSuspenseQuery } from '@apollo/client'
import { type FC, type PropsWithChildren, createContext, useContext, useMemo } from 'react'
import type { IntrospectionField, IntrospectionQuery } from '~/src/__generated__/graphql'
import IntrospectionQuerySchema from '~/src/graphql/schemas/introspection/introspection.graphql'

export type IntrospectionQueryFieldMap = Record<string, IntrospectionField>
export type IntrospectionQueryContext = {
  [endpointKey: string]: IntrospectionQueryFieldMap
}

export type IntrospectionQueryFields = Record<string, IntrospectionField[]>

export const IntrospectionDetailContext = createContext<IntrospectionQuery[]>(null)
IntrospectionDetailContext.displayName = 'IntrospectionDetailContext'

export const useIntrospectionQuery = (): IntrospectionQueryContext => {
  const data = useContext(IntrospectionDetailContext)
  return useMemo(() => {
    return data.reduce((acc: Record<string, Record<string, IntrospectionField>>, nextEndpoint) => {
      acc[nextEndpoint.endpoint] = nextEndpoint.fields.reduce(
        (acc: Record<string, IntrospectionField>, field) => {
          acc[field.field] = field
          return acc
        },
        {},
      )
      return acc
    }, {})
  }, [data])
}

export const useIntrospectionQueryEndpoint = (endpoint: string): IntrospectionQueryFieldMap => {
  const introspectionQuery = useIntrospectionQuery()
  return useMemo(() => introspectionQuery[endpoint], [introspectionQuery, endpoint])
}

export const useIntrospectionQueryEndpointField = (
  endpoint: string,
  field: string,
): IntrospectionField => {
  const introspectionEndpoint = useIntrospectionQueryEndpoint(endpoint)
  return introspectionEndpoint?.[field]
}

type ProviderProps = {
  endpoints?: string[]
}

export const IntrospectionDetailProvider: FC<PropsWithChildren<ProviderProps>> = ({
  children,
  endpoints,
}) => {
  const { data } = useSuspenseQuery<any>(IntrospectionQuerySchema, {
    variables: {
      endpoints,
    },
  })

  return (
    <IntrospectionDetailContext.Provider value={data?.Introspection}>
      {children}
    </IntrospectionDetailContext.Provider>
  )
}
