import { CurrencyType } from '~/src/__generated__/graphql'

export enum FieldGroups {
  general = 0,
  activePlayer = 1,
  drawSettings = 2,
  poolSettings = 3,
  hitDistribution = 4,
}

export const PrizeDistributionRuleParamName = 'PrizeDistributionRule'

export const CurrencyTypeJackpotForm = [CurrencyType.Custom, CurrencyType.System]
