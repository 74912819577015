export const weekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const weekDaysShort = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
