import type { Duration } from 'date-fns'
import { pipe } from 'fp-ts/lib/function'
import { curryN } from 'ramda'
import { convertSeconds } from './convert-seconds'

const pattern = '##'
const replacer = curryN(3, (token: string, value: string, mask: string) => {
  return mask.replace(`${pattern}${token}`, `${value}${token}`)
})

export function formatMaskFromDuration(duration: Duration, mask: string): string {
  const days = duration.days ? duration.days.toString().padStart(3, '0') : '000'
  const hours = duration.hours ? duration.hours.toString().padStart(2, '0') : '00'
  const minutes = duration.minutes ? duration.minutes.toString().padStart(2, '0') : '00'
  const seconds = duration.seconds ? duration.seconds.toString().padStart(2, '0') : '00'

  return pipe(
    mask,
    replacer('d', days),
    replacer('h', hours),
    replacer('m', minutes),
    replacer('s', seconds),
  )
}

export function formatMaskFromSeconds(seconds: number, mask: string): string {
  return formatMaskFromDuration(convertSeconds(seconds), mask)
}
