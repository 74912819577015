import { invertObj } from 'ramda'
import { ServiceNames } from '~/src/constants/entity/service/service-names'

export const serviceLabelMap: Record<ServiceNames, string> = {
  [ServiceNames.Deposit]: 'DEPOSIT',
  [ServiceNames.Withdraw]: 'WITHDRAWAL',
  [ServiceNames.JackpotOwnershipInterval]: '24H',
  [ServiceNames.JackpotOwnershipCycle]: 'CYCLE',
  [ServiceNames.JackpotOwnershipBets]: 'BETS',
  [ServiceNames.JackpotOwnershipFixedPool]: 'FIXED POOL',
  [ServiceNames.JackpotOwnershipProgressivePool]: 'PROGRESSIVE POOL',
}
export const serviceLabelMapInvert: Record<string, ServiceNames> = invertObj(
  serviceLabelMap,
) as Record<string, ServiceNames>
