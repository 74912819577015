import type { ApolloQueryResult } from '@apollo/client'
import refreshTokenQuerySchema from '~/src/graphql/schemas/refresh-token.graphql'
import type { ApolloClientMemoryCache } from '~/src/types/apollo'
import type { RefreshTokenQueryResults } from './types'

export const refreshTokenQuery = (
  apolloClient: ApolloClientMemoryCache,
): Promise<ApolloQueryResult<RefreshTokenQueryResults>> => {
  return apolloClient.query({
    query: refreshTokenQuerySchema,
    fetchPolicy: 'no-cache',
  })
}
