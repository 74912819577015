import type { JackpotSchedulerSpan } from '~/src/__generated__/graphql'

const isSpanContainingOffset = (start: number, end: number, offset: number): boolean => {
  // crossing interval boundary
  if (end > start) {
    return offset >= start && offset < end
  }
  return offset >= start || offset < end
}

export const validateExclusiveSpan = (
  spans: JackpotSchedulerSpan[],
  spanIndex: number,
  { startSec, endSec }: JackpotSchedulerSpan,
) => {
  return spans.filter((span, currentSpanIndex) => {
    if (currentSpanIndex === spanIndex) {
      return false
    }

    const spanStartSec = span.startSec
    const spanEndSec = span.endSec

    if (spanStartSec === spanEndSec) {
      return false
    }

    return (
      // 1. The range doesn't cross the interval boundary
      // 1.1 Span includes `start
      //  [>>span>>]
      //  ------[start]>>>>>>[end]------
      //  OR
      //  >>>>>span>>>>>>]         [>>>>
      //  ------[start]>>>>>>[end]------
      isSpanContainingOffset(spanStartSec, spanEndSec, startSec) ||
      isSpanContainingOffset(startSec, endSec, spanStartSec) ||
      // 1.2 Span starts inside the range
      //               [>>>>span>>>>>]
      // ------[start]>>>>>>[end]------
      (spanStartSec > startSec && spanStartSec < endSec)
    )
  })
}

export const validateInclusiveSpan = (
  spans: JackpotSchedulerSpan[],
  spanIndex: number,
  { startSec, endSec }: JackpotSchedulerSpan,
) => {
  return spans.filter((span, currentSpanIndex) => {
    if (currentSpanIndex === spanIndex) {
      return false
    }

    const spanStartSec = span.startSec
    const spanEndSec = span.endSec

    if (spanStartSec === spanEndSec) {
      return false
    }

    return (
      // 2.1 Span starts in the same interval as `end`
      //  [span]
      // >>>>>>[end]------[start]>>>>>>
      isSpanContainingOffset(spanStartSec, spanEndSec, startSec) ||
      isSpanContainingOffset(startSec, endSec, spanStartSec) ||
      // 1.2 Span starts inside the range
      //               [>>>>span>>>>>]
      // ------[start]>>>>>>[end]------
      spanStartSec >= startSec
    )
  })
}
