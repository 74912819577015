/**
 * 0.000000001 %
 */
export const StandardPercentFractionDigits = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 9,
}

/**
 * Min fraction digits for unit decimals: 1e-12
 */
export const UnitMacroDecimalPartSize = 12
