import { useEffect } from 'react'
import { isSSR } from '~/src/utils/is-server'

const numberReqex = /^[-]?(\d+)([,.]\d+)?$/

export enum Code {
  upArrow = 38,
  downArrow = 40,
  e = 69,
  equalSign = 187,
  // dash = 189,
}

const handlerKeyDown = (event: KeyboardEvent): void => {
  const target = event.target as HTMLInputElement
  if (target.tagName === 'INPUT' && target.type === 'number') {
    const charCode = event.keyCode
    if (
      charCode === Code.upArrow ||
      charCode === Code.downArrow ||
      charCode === Code.e ||
      charCode === Code.equalSign
    ) {
      event.preventDefault()
    }
  }
}

const handlePaste = (event: Event): void => {
  const target = event.target as HTMLInputElement
  if (target.tagName === 'INPUT' && target.type === 'number') {
    const pastedData = (event as ClipboardEvent).clipboardData.getData('Text').toUpperCase()
    if (!numberReqex.test(pastedData)) {
      event.stopPropagation()
      event.preventDefault()
    }
  }
}

const handlerWheel = (event: Event): void => {
  const target = event.target as HTMLInputElement
  if (target.tagName === 'INPUT' && target.type === 'number') {
    event.stopPropagation()
    event.preventDefault()
  }
}

const handlerFocusout = (event: Event): void => {
  const target = event.target as HTMLInputElement
  if (target.tagName === 'INPUT' && target.type === 'number') {
    target.removeEventListener('wheel', handlerWheel)
    target.removeEventListener('focusout', handlerFocusout)
  }
}

const handlerFocusin = (event: Event): void => {
  const target = event.target as HTMLInputElement
  if (target.tagName === 'INPUT' && target.type === 'number') {
    target.addEventListener('wheel', handlerWheel, { passive: false })
    target.addEventListener('focusout', handlerFocusout)
  }
}

const useInputNumber = () => {
  useEffect(() => {
    if (!isSSR) {
      window.addEventListener('keydown', handlerKeyDown)
      window.addEventListener('paste', handlePaste)
      window.addEventListener('focusin', handlerFocusin)
      return () => {
        window.removeEventListener('keydown', handlerKeyDown)
        window.removeEventListener('paste', handlePaste)
        window.removeEventListener('focusin', handlerFocusin)
      }
    }
  }, [])
}

export default useInputNumber
