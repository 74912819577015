export enum GTM_EVENT {
  SUB_MENU_CLICK = 'sub_menu_click',
  HEADER_MENU_CLICK = 'header_menu_click',
  SCROLL_DEPTH = 'scroll_depth',
  ELEMENT_VISIBILITY = 'element_visibility',
  ELEMENT_USAGE_TIME = 'element_usage_time',
  TABLE_CELL_CLICK = 'table_cell_click',
  DRAWER_CLICK = 'drawer_click',
  BUTTON_CLICK = 'button_click',
  TABLE_FILTER = 'table_filter',
  TABLE_SORT = 'table_sort',
  TAB_ACTIVATE = 'tab_activate',
}

export enum GTM_DATASET {
  EVENT = 'gtmEvent',
  ITEM_ID = 'gtmItemId',
}

export enum GTM_ATTRIBUTE {
  GTM_EVENT = 'data-gtm-event',
  GTM_ITEM_ID = 'data-gtm-item-id',
}

type GtmParamType = Partial<Record<GTM_DATASET, string>>

export const GTM_VARIABLE_ITEM_ID = 'itemId'

export const GTM_PARAM: GtmParamType = {
  [GTM_DATASET.ITEM_ID]: GTM_VARIABLE_ITEM_ID,
}
export const GTM_PARAMS = Object.keys(GTM_PARAM)

export type GtmAttributeValue = string
export type GtmUserPropertyType = Partial<Record<GTM_ATTRIBUTE, GtmAttributeValue>>

export const GtmTableFilterNameParam = 'filterName'
export const GtmTableSortNameParam = 'sortName'
export const GtmTableSortDirParam = 'sortDir'
