import { format } from 'date-fns'
import type { LocaleObject } from 'yup/lib/locale'
import { DATE_PICKER_FORMAT_EN } from '~/src/constants/dates'

export const yupLocale: LocaleObject = {
  mixed: {
    default: (params) => {
      switch (params.type) {
        case 'unique': {
          return {
            key: 'common:validations.unique',
          }
        }
        default: {
          return {
            key: 'common:validations.invalid',
          }
        }
      }
    },
    required: {
      key: 'common:validations.required',
    },
    notType: ({ type }) => ({
      key: 'common:validations.notType',
      values: { type },
    }),
    oneOf: ({ values }) => ({
      key: 'common:validations.oneOf',
      values: { values },
    }),
  },
  string: {
    email: {
      key: 'common:validations.email',
    },
    min: ({ min }) => ({
      key: 'common:validations.stringMin',
      values: { min },
    }),
    max: ({ max }) => ({
      key: 'common:validations.stringMax',
      values: { max },
    }),
    matches: ({ regex }) => ({
      key: 'common:validations.match',
      values: { regex: regex.source },
    }),
  },
  number: {
    min: ({ min }) => ({
      key: 'common:validations.numberMin',
      values: { min },
    }),
    max: ({ max }) => ({
      key: 'common:validations.numberMax',
      values: { max },
    }),
    positive: {
      key: 'common:validations.positive',
    },
    integer: {
      key: 'common:validations.integer',
    },
  },
  date: {
    min: ({ min }) => ({
      key: 'common:validations.dateMin',
      values: { min: format(new Date(min), DATE_PICKER_FORMAT_EN) },
    }),
    max: ({ max }) => ({
      key: 'common:validations.dateMax',
      values: { max: format(new Date(max), DATE_PICKER_FORMAT_EN) },
    }),
  },
  boolean: {},
}
