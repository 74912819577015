import { weekdays } from '~/src/constants'

const daySeconds = 3600 * 24

export function convertSeconds(seconds: number) {
  const days = Math.floor(seconds / daySeconds)
  seconds -= days * daySeconds
  const hours = Math.floor(seconds / 3600)
  seconds -= hours * 3600
  const minutes = Math.floor(seconds / 60)
  seconds -= minutes * 60

  return {
    days,
    hours,
    minutes,
    seconds,
  }
}

export function secondsToWeekdayTime(seconds: number): {
  weekday: string
  hours: number
  minutes: number
} {
  const secondsInADay = 24 * 60 * 60

  const dayIndex = Math.floor(seconds / secondsInADay)
  const weekday = weekdays[dayIndex % 7]

  const remainingSeconds = seconds % secondsInADay
  const hours = Math.floor(remainingSeconds / 3600)
  const minutes = Math.floor((remainingSeconds % 3600) / 60)

  return {
    weekday,
    hours,
    minutes,
  }
}
