import { type ApolloError, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import type { User } from '~/src/__generated__/graphql'
import { isUserJA } from '~/src/helpers/users/permissions'
import { getUserSessionQuery } from '~/src/service/session/load-user/load-user'

export type SessionRichUser = User & {
  isJA: boolean
}

type UserSessionQueryResult = { my: SessionRichUser }
type API = {
  loading: boolean
  error: ApolloError
  user: SessionRichUser
}

export const useLoadUser = (): API => {
  const { loading, error, data } = useQuery<UserSessionQueryResult>(getUserSessionQuery)
  const user: SessionRichUser = useMemo(() => {
    const my = data?.my
    // Does not allow empty object spread when my is null
    return my
      ? {
          ...my,
          isJA: isUserJA(my),
        }
      : my
  }, [data?.my])

  return {
    loading,
    error,
    user,
  }
}
