import { Slide } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import type { ReactNode } from 'react'
import ErrorNotification from './components/error'
import SuccessNotification from './components/success'

export const NOTIFICATION_AUTO_HIDE_DURATION = 5000

declare module 'notistack' {
  interface VariantOverrides {
    SuccessNotification: true
    ErrorNotification: true
  }
}

type Props = {
  children?: ReactNode | ReactNode[]
}

const NotificationsProvider = ({ children }: Props) => {
  return (
    <SnackbarProvider
      autoHideDuration={NOTIFICATION_AUTO_HIDE_DURATION}
      Components={{
        SuccessNotification,
        ErrorNotification,
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={(props) => <Slide direction="down" {...props} />}
    >
      {children}
    </SnackbarProvider>
  )
}

export default NotificationsProvider
