import type { ErrorHandlers } from '../types'
import { graphqlErrorHandler } from './graphql-error-handler'
import { networkErrorHandler } from './network-error-handler'

export const getErrorHandlers = (onError: (error: Error) => void): ErrorHandlers => {
  return {
    graphqlErrorHandler: graphqlErrorHandler({
      onError,
    }),
    networkErrorHandler,
  }
}
