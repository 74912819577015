import { curryN } from 'ramda'
import type { AccessRightsPermission, User } from '~/src/__generated__/graphql'

import { JSAMainUserName, JSAMainUserNameNew } from '~/src/constants'

export const reducePermissions = (
  acc: Record<string, boolean>,
  { permission, isAllowed }: AccessRightsPermission,
) => ({
  ...acc,
  [permission]: isAllowed,
})

export const filterPermissions = curryN(
  2,
  (permissionNames: string[], { permission }: AccessRightsPermission) =>
    permissionNames.includes(permission),
)

// TODO: Remove "||" after jsa user name migration on backend will be done
export const isUserJA = (user: User) =>
  user?.client?.name === JSAMainUserName || user?.client?.name === JSAMainUserNameNew
