import type { ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import Observable from 'zen-observable'
import type { ApolloClientMemoryCache } from '~/src/types/apollo'
import type { ErrorHandlers } from './types'

export const createErrorLink = (
  getClient: () => ApolloClientMemoryCache,
  errorHandlers?: ErrorHandlers,
): ApolloLink => {
  const { graphqlErrorHandler, networkErrorHandler } = errorHandlers ?? {}
  return onError((errorResponse) => {
    const { graphQLErrors, networkError } = errorResponse
    const retryErrorOperations: Observable<any>[] = []

    if (networkError) {
      if (networkErrorHandler) {
        networkErrorHandler(networkError)
      }
    }

    if (graphQLErrors) {
      const client = getClient()

      graphQLErrors.forEach((error) => {
        const handler$ = graphqlErrorHandler(error, errorResponse, client)
        if (handler$) {
          retryErrorOperations.push(handler$)
        }
      })

      return retryErrorOperations.length > 0
        ? retryErrorOperations.reduce(
            (acc$, nextHandler$) => acc$.concat(nextHandler$),
            Observable.of(),
          )
        : undefined
    }
  })
}
