import { useCallback, useState } from 'react'
import { ROUTES } from '~/src/constants'
import { getErrorHandlers } from '~/src/lib/apollo/errors/error-handlers'
import type { AppController } from '~/src/types/app/controller'

export const useAppController = (): AppController => {
  const [redirect, setRedirect] = useState(null)

  const getErrorHandlersCb = useCallback(
    () =>
      getErrorHandlers(() => {
        setRedirect(ROUTES.login)
      }),
    [],
  )

  return {
    redirect,
    getErrorHandlers: getErrorHandlersCb,
    clearRedirect: () => {
      setRedirect(null)
    },
  }
}
