export enum UserRole {
  secondary = 'secondary',
  primary = 'primary',
}

export const IsMailSendDisabledParam = 'isMailSendDisabled'
export const IsUserCreatedParam = 'created'
export const IsMailSendDisabledQueryValue = '1'
export const IsMailSendEnabledQueryValue = '0'
export const IsUserCreatedParamQueryValue = '1'
