import {
  type Dispatch,
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useReducer,
} from 'react'

enum DialogActionType {
  OPEN_DIALOG = 'OPEN_DIALOG',
  CLOSE_DIALOG = 'CLOSE_DIALOG',
}

type DialogContextType<DialogProps extends object> = {
  isOpen: boolean
  dialogProps?: DialogProps
}

type DialogStorageContextType<DialogProps extends object> = {
  [id: string]: DialogContextType<DialogProps>
}

type DialogAction<DialogProps extends object> = {
  type: DialogActionType
  id: string
  dialogProps?: DialogProps
}

const reducerInitialValue = {}
const defaultDialogContext: DialogContextType<any> = {
  isOpen: false,
  dialogProps: null,
}

function dialogReducer<DialogProps extends object>(
  state: DialogStorageContextType<DialogProps>,
  action: DialogAction<DialogProps>,
) {
  const { id, type, dialogProps } = action
  const dialog = state[id]

  switch (type) {
    case DialogActionType.OPEN_DIALOG: {
      return {
        ...state,
        [id]: {
          isOpen: true,
          dialogProps,
        },
      }
    }
    case DialogActionType.CLOSE_DIALOG: {
      return {
        ...state,
        [id]: {
          /**
           * Preserve current dialog props when closing for consistency
           */
          ...dialog,
          isOpen: false,
        },
      }
    }
    default: {
      throw Error(`Unknown action: ${action.type}`)
    }
  }
}

const DialogContext = createContext<DialogStorageContextType<any>>({})
const DialogDispatchContext = createContext<Dispatch<DialogAction<any>>>(null)

export const useDialogActions = <DialogProps extends object>() => {
  const dispatch = useContext(DialogDispatchContext)
  if (!dispatch) {
    throw new Error('useDialogActions must be used within a DialogProvider')
  }

  const open = useCallback(
    (id: string, dialogProps: DialogProps) =>
      dispatch({ type: DialogActionType.OPEN_DIALOG, id, dialogProps }),
    [dispatch],
  )

  const close = useCallback(
    (id: string) => dispatch({ type: DialogActionType.CLOSE_DIALOG, id }),
    [dispatch],
  )

  return {
    open,
    close,
  }
}

export const useDialogContext = <DialogProps extends object>(id: string) => {
  const context = useContext<DialogStorageContextType<DialogProps>>(DialogContext)
  const actions = useDialogActions<DialogProps>()

  let dialog = context[id]

  if (!dialog) {
    dialog = defaultDialogContext
  }

  return {
    ...dialog,
    openDialog: useCallback((props?: any) => actions.open(id, props), [actions.open, id]),
    closeDialog: useCallback(() => actions.close(id), [actions.close, id]),
  }
}

export const DialogProvider = ({ children }: { children: ReactNode }) => {
  const [dialogState, dialogDispatch] = useReducer(dialogReducer, reducerInitialValue)

  return (
    <DialogContext.Provider value={dialogState}>
      <DialogDispatchContext.Provider value={dialogDispatch}>
        {children}
      </DialogDispatchContext.Provider>
    </DialogContext.Provider>
  )
}
