import { datadogLogs } from '@datadog/browser-logs'
import {
  DatadogBrowserLogsEnabled,
  DatadogClientToken,
  DatadogEnv,
  DatadogService,
  DatadogSite,
  DatadogVersion,
} from '~/src/constants'
import { isSSR } from '~/src/utils/is-server'

// Browser logs should be initialized only for client-side
if (!isSSR && DatadogBrowserLogsEnabled) {
  datadogLogs.init({
    clientToken: DatadogClientToken,
    site: DatadogSite,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    telemetrySampleRate: 100,
    service: DatadogService,
    version: DatadogVersion,
    env: DatadogEnv,
  })
}
