import { serialize as cookieSerialize } from 'cookie'

export const serializeCookieObject = (cookies: Record<string, any>): string => {
  if (!cookies) {
    return ''
  }

  return Object.keys(cookies)
    .reduce((acc, nextKey) => acc.concat(cookieSerialize(nextKey, cookies[nextKey])), [])
    .join('; ')
}
