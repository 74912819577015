import { useCallback } from 'react'
import { StandardPercentFractionDigits } from '~/src/constants/format'
import type { CurrencyFormatterAPI } from '~/src/hooks/i18n/use-currency-format'
import type { UseNumberFormat } from '~/src/hooks/i18n/use-number-format'
import { ServiceNames } from './service-names'

export enum ServiceTypeValue {
  fixed = 0,
  percent = 1,
}

export const serviceType: Record<ServiceNames, ServiceTypeValue> = {
  [ServiceNames.Deposit]: ServiceTypeValue.fixed,
  [ServiceNames.Withdraw]: ServiceTypeValue.fixed,
  [ServiceNames.JackpotOwnershipInterval]: ServiceTypeValue.fixed,
  [ServiceNames.JackpotOwnershipCycle]: ServiceTypeValue.fixed,
  [ServiceNames.JackpotOwnershipBets]: ServiceTypeValue.percent,
  [ServiceNames.JackpotOwnershipFixedPool]: ServiceTypeValue.percent,
  [ServiceNames.JackpotOwnershipProgressivePool]: ServiceTypeValue.percent,
}

export const serviceTypeLabel: Partial<Record<ServiceNames, string>> = {
  [ServiceNames.JackpotOwnershipBets]: '%BET',
  [ServiceNames.JackpotOwnershipFixedPool]: '%POOL',
  [ServiceNames.JackpotOwnershipProgressivePool]: '%POOL',
}

type CurrencyScale = {
  scale: number
}

export const useServiceValue = (
  currency: CurrencyScale,
  formatNumber: UseNumberFormat,
  formatCurrency: CurrencyFormatterAPI,
) => {
  return useCallback(
    (param: { amount: number; percent: number }, serviceName: ServiceNames) => {
      return serviceType[serviceName] === ServiceTypeValue.fixed
        ? formatCurrency.format({ currency }, param.amount)
        : formatNumber.format(param.percent, StandardPercentFractionDigits)
    },
    [formatNumber, formatCurrency, currency],
  )
}
