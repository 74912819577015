import { useRouter } from 'next/router'
import { useEffect } from 'react'

type Props = {
  redirect: string
  postRedirect?: () => void
}

export const useAuthRedirect = ({ redirect, postRedirect }: Props): void => {
  const router = useRouter()

  useEffect(() => {
    if (redirect) {
      router.push(redirect)
      if (postRedirect) {
        postRedirect()
      }
    }
  }, [redirect, postRedirect])
}
