export const DatadogEnvSandbox = 'sandbox' // dev
export const DatadogEnvStaging = 'staging'
export const DatadogEnvProduction = 'production'
export const DatadogEnvProductionMT = 'production-mt'
export const DatadogEnvQA1 = 'qa1'
export const DatadogEnvDemo = 'demo'
export const DatadogEnvIntegration = 'integration'
export const DatadogClientToken = process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN
export const DatadogSite = process.env.NEXT_PUBLIC_DD_ENDPOINT
export const DatadogService = process.env.NEXT_PUBLIC_DD_SERVICE
export const DatadogVersion = process.env.NEXT_PUBLIC_DD_VERSION
export const DatadogEnv = process.env.NEXT_PUBLIC_DD_ENV
export const DatadogApplicationID = process.env.NEXT_PUBLIC_DD_APP_ID

const IsDatadogEnabledEnv = [
  DatadogEnvSandbox,
  DatadogEnvStaging,
  DatadogEnvProduction,
  DatadogEnvProductionMT,
  DatadogEnvQA1,
  DatadogEnvDemo,
  DatadogEnvIntegration,
].includes(DatadogEnv)

/**
 * https://app.datadoghq.eu/logs
 */
export const DatadogBrowserLogsEnabled = IsDatadogEnabledEnv

/**
 * https://app.datadoghq.eu/rum
 */
export const DatadogBrowserRUMEnabled = IsDatadogEnabledEnv
