/**
 * Keep these values in sync with BFF
 */
export const AUTH_TOKEN_COOKIE_KEY = 'jsa_system_authToken'
export const REFRESH_TOKEN_COOKIE_KEY = 'jsa_system_refreshToken'
export const AUTH_REDIRECT_URL = 'jsa_auth_redirect_url'

/**
 * Keep these values in sync with BFF
 */
export const AUTH_TOKEN_LIFETIME = 1 * 60 * 60 // 1 hr
export const REFRESH_TOKEN_LIFETIME = 30 * 24 * 60 * 60 // 30 days
