import { type FC, type PropsWithChildren, createContext, useContext } from 'react'
import type { AccessRightsPermission } from '~/src/__generated__/graphql'
import { filterPermissions, reducePermissions } from '~/src/helpers/users/permissions'
import { useLoadUser } from '~/src/hooks/session/use-load-user'

export const PermissionContext = createContext<AccessRightsPermission[]>(null)
PermissionContext.displayName = 'PermissionContext'

export const usePermission = (permissionName: string): boolean => {
  const permissions = useContext(PermissionContext)
  return permissions?.find(
    ({ permission }: AccessRightsPermission) => permission === permissionName,
  )?.isAllowed
}

export const usePermissionsAnd = (permissionNames: string[]): boolean => {
  const permissions = useContext(PermissionContext)
  return permissions
    .filter(filterPermissions(permissionNames))
    .reduce<boolean>((isAllowed, next) => {
      return isAllowed && next.isAllowed
    }, true)
}

// export const usePermissionsOr

export const useAllPermissions = () => {
  const permissions = useContext(PermissionContext)
  return permissions?.reduce(reducePermissions, {})
}

/**
 * Should go below ApolloProvider as it uses apollo query
 */
export const PermissionProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user, loading } = useLoadUser()

  return (
    <PermissionContext.Provider value={loading ? [] : user?.permissions}>
      {children}
    </PermissionContext.Provider>
  )
}
