import { Observable, fromPromise } from '@apollo/client'
import type { ErrorResponse } from '@apollo/client/link/error'
import type { GraphQLError } from 'graphql'
import {
  APIErrors,
  ErrorMapping,
  type ForbiddenErrorResponse,
  type ForbiddenErrorResponseLegacy,
  type InternalServerErrorResponseType,
} from '~/src/constants/error'
import { ErrorCodes } from '~/src/lib/apollo/errors/errors'
import { refreshTokenQuery } from '~/src/service/auth/refresh-query'
import type { ApolloClientMemoryCache } from '~/src/types/apollo'

let isRefreshing = false
let pendingRequests: (() => void)[] = []

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback())
  pendingRequests = []
}

/**
 * To swallow the error return Observable.of() - but ensure to log the error
 * @param param0
 * @returns
 */
export const graphqlErrorHandler =
  ({ onError }: { onError: (error: Error) => void }) =>
  (
    error: GraphQLError,
    { forward, operation, response }: ErrorResponse,
    client: ApolloClientMemoryCache,
  ): Observable<any> => {
    const errorResponse = (error as GraphQLError).extensions
      .response as InternalServerErrorResponseType

    switch (error.extensions.code) {
      case ErrorCodes.INTERNAL_SERVER_ERROR: {
        if (errorResponse?.body === APIErrors.INVALID_TOKEN_500) {
          // biome-ignore lint/nursery/noConsole: allow console debug info to be swallowed by datadog agent
          console.log('Invalid token error: ', JSON.stringify(error.extensions.response))
          return Observable.of(null)
        }

        if (errorResponse?.body === APIErrors.INVALID_REFRESH_TOKEN_500) {
          // biome-ignore lint/nursery/noConsole: allow console debug info to be swallowed by datadog agent
          console.log('Invalid token error: ', JSON.stringify(error.extensions.response))
          return Observable.of(null)
        }

        break
      }
      case ErrorCodes.FORBIDDEN: {
        let forward$: Observable<boolean>

        const extensions = (error as GraphQLError).extensions
        const errorResponseLegacy = extensions.response as ForbiddenErrorResponseLegacy
        const errorResponse = extensions.response as ForbiddenErrorResponse

        /**
         * Old-styled format: backend just returns single error { error: "user hasn't policy" }
         */
        if (errorResponseLegacy?.body?.error === APIErrors.FORBIDDEN_ACCESS_403) {
          return Observable.of(response)
        }

        /**
         * Response has errors (new backend error format { errors: [{...}] }) and first error is 403
         */
        if (
          errorResponse?.body?.errors?.[0].code === ErrorMapping.UNKNOWN_ERROR &&
          // Invalid token needs session restore
          errorResponse?.body?.errors?.[0].detail !== APIErrors.FORBIDDEN_INVALID_TOKEN
        ) {
          return Observable.of(response)
        }

        const errorResponseWithStringBody = extensions.response as { body: string }

        if (errorResponseWithStringBody.body === APIErrors.FORBIDDEN_INVALID_TOTP) {
          return Observable.of(response)
        }

        // Session restore + request reattempt
        if (!isRefreshing) {
          isRefreshing = true
          forward$ = fromPromise(
            refreshTokenQuery(client)
              .then(() => {
                resolvePendingRequests()
                return true
              })
              .catch((error) => {
                pendingRequests = []
                onError(error)
                return false
              })
              .finally(() => {
                isRefreshing = false
              }),
          ).filter((value) => Boolean(value))
        } else {
          // Will only emit once the Promise is resolved
          forward$ = fromPromise(
            new Promise((resolve: any) => {
              pendingRequests.push(() => resolve(true))
            }),
          )
        }

        return forward$.flatMap(() => forward(operation))
      }
    }
  }
