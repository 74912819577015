export enum ProductTypeName {
  banking = 'banking',
  jackpotOwnership = 'jackpot-ownership',
  jackpotOperating = 'jackpot-operating',
}

export const ProductTypeLabels: Record<string, string> = {
  [ProductTypeName.banking]: 'Banking',
  [ProductTypeName.jackpotOwnership]: 'Jackpot ownership',
  [ProductTypeName.jackpotOperating]: 'Jackpot operating',
}
