const ICU = require('i18next-icu-fork')

const DEFAULT_LOCALE = 'en'

module.exports = {
  i18n: {
    defaultLocale: DEFAULT_LOCALE,
    locales: [DEFAULT_LOCALE /*, 'ru'*/],
  },
  use: [
    new ICU({
      formats: {
        number: {
          BASIC_NUMBER_FRACTION_DIGITS: {
            minimumFractionDigits: 0,
            maximumFractionDigits: 9,
            useGrouping: false,
          },
        },
      },
    }),
    // Backend - Use i18next-http-backend when required to load translations from Crowdin
  ],
  /**
   * i18next-icu plugin is causing issues:
   * https://github.com/isaachinman/next-i18next#unserialisable-configs
   */
  serializeConfig: false,
  debug: process.env.NODE_ENV === 'development',
}
