import { InMemoryCache } from '@apollo/client'
import { TariffStore } from '~/src/store/tariffs'

/**
 * Should create new cache for each new apolloClient instance
 */
export const createCache: () => InMemoryCache = () =>
  new InMemoryCache({
    typePolicies: {
      ...TariffStore,
    },
  })
