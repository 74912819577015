import type { FieldFunctionOptions, TypePolicies } from '@apollo/client'
import type { Tariff } from '~/src/__generated__/graphql'
import { selectedTariffID } from './selected-tariff'

export * from './selected-tariff'

export const TariffStore: TypePolicies = {
  Tariff: {
    fields: {
      isSelected: (_: Tariff, opts: FieldFunctionOptions): boolean => {
        return selectedTariffID() === opts.readField('id')
      },
    },
  },
}
