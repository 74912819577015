export enum ErrorMapping {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  UNKNOWN_VALIDATION = 'UNKNOWN_VALIDATION',
  VALIDATION_REQUIRED = 'VALIDATION_REQUIRED',
  VALIDATION_INVALID = 'VALIDATION_INVALID',
  ACCOUNT_MIN_BALANCE_CONSTRAINT_VIOLATION = 'ACCOUNT/MIN_BALANCE_CONSTRAINT_VIOLATION',
}

export enum APIErrors {
  INVALID_TOKEN_500 = 'repository error: invalid token',
  INVALID_REFRESH_TOKEN_500 = 'repository error: failed to get user by refresh token',
  FORBIDDEN_ACCESS_403 = "user hasn't policy",
  FORBIDDEN_INVALID_TOKEN = 'token error: failed to parse token with claims: token contains an invalid number of segments',
  FORBIDDEN_INVALID_TOTP = 'invalid totp code',
}

export type ForbiddenErrorResponseLegacy = {
  body: {
    error: string
  }
}

export type ForbiddenErrorResponse = {
  body: {
    errors: {
      code: ErrorMapping
      detail: string
    }[]
  }
}

export type InternalServerErrorResponseBodyObject = {
  error: string
  errors?: {
    code: ErrorMapping
    detail: string
  }[]
}

export type InternalServerErrorResponseType = {
  body: string | InternalServerErrorResponseBodyObject
  status?: number
}
