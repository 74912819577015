import { ProductTypeName } from '~/src/constants/entity/product/product'
import { ServiceNames } from '~/src/constants/entity/service/service-names'

export const serviceMap: Record<ProductTypeName, string[]> = {
  [ProductTypeName.banking]: [ServiceNames.Deposit, ServiceNames.Withdraw],
  [ProductTypeName.jackpotOwnership]: [
    ServiceNames.JackpotOwnershipInterval,
    ServiceNames.JackpotOwnershipCycle,
    ServiceNames.JackpotOwnershipBets,
    ServiceNames.JackpotOwnershipFixedPool,
    ServiceNames.JackpotOwnershipProgressivePool,
  ],
  [ProductTypeName.jackpotOperating]: [],
}
