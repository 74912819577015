export const formMode = 'onSubmit'

export const formCommonProps: {
  reValidateMode: 'onBlur' | 'onChange' | 'onSubmit'
} = {
  // validation strategy after triggered primary validation
  reValidateMode: 'onBlur',
}

export const MAX_MONEY = 1e12
export const MAX_INTEGER = Number.MAX_SAFE_INTEGER

export const PERCENT_DECIMAL_PART_STANDARD = 9
