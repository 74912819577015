import { Alert, Typography, useTheme } from '@mui/material'
import { type CustomContentProps, useSnackbar } from 'notistack'
import { type ForwardRefRenderFunction, type Ref, forwardRef, useCallback } from 'react'

type ErrorNotificationProps = CustomContentProps & {
  forwardRef: Ref<HTMLDivElement>
}

const ErrorNotification = (props: ErrorNotificationProps) => {
  const theme = useTheme()
  const { closeSnackbar } = useSnackbar()

  const onClick = useCallback(() => {
    closeSnackbar(props.id)
  }, [props.id, closeSnackbar])

  return (
    <Alert
      ref={props.forwardRef}
      sx={{ margin: theme.spacing(1), padding: 0, paddingX: theme.spacing(2) }}
      onClick={onClick}
      color="error"
    >
      <Typography variant="body2">{props.message}</Typography>
    </Alert>
  )
}

const forwardingRefFn: ForwardRefRenderFunction<HTMLDivElement, CustomContentProps> = (
  props: CustomContentProps,
  ref: Ref<HTMLDivElement>,
) => <ErrorNotification {...props} forwardRef={ref} />

export default forwardRef<HTMLDivElement, CustomContentProps>(forwardingRefFn)
