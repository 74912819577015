const SAFE_TOKEN_BUFFER_SECS = 30

export const msToSeconds = (ms: number): number => {
  if (ms < 1000) {
    return 1
  }
  return Math.ceil(ms / 1000)
}

export const getExpCompare = (): number => {
  return msToSeconds(Date.now())
}

/**
 * Verify token payload expire field ('exp')
 *
 * Following conditions are considered as invalid token:
 *    Less than 30 secs remaining to date / expired to date -> false
 *    30 secs remaining to date -> false
 *    Broken token -> false
 *
 * Token is valid when it's valid and there is more than 30 secs to expire date
 * 30 secs is a buffer to fetch some queries on that server call
 */
export const isTokenValid = (token: string, expCompare: number): boolean => {
  if (!token) {
    // biome-ignore lint/nursery/noConsole: debug
    console.log('No token')
    return null
  }

  try {
    const [, payload] = token.split('.')
    const decoded = Buffer.from(payload, 'base64')
    const payloadData = JSON.parse(decoded.toString('ascii'))
    /**
     * Consider timezone
     */
    return payloadData.exp > expCompare + SAFE_TOKEN_BUFFER_SECS
  } catch (_e) {
    return false
  }
}
